import React, { Component } from "react";

import "./UiSelect.scss";

import { ReactComponent as SelectDownIcon } from "../../assets/images/ui/select-down.svg";

class UiSelect extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeOption: "",
    selectValue: null,
  };

  componentDidMount() {
    //if( this.props.optionList[0]) this.setState({ activeOption: this.props.optionList[0].option })
  }

  handleChange = (e) => {
    this.setState({ selectValue: e.target.value });
    if (this.props.onChange) this.props.onChange(e.target.value); 
  };

  render() {
    if (this.props.optionList) {
      var optionList = this.props.optionList.map((item, index) => { 
        return (
          <option key={index} value={item.value} selected={this.props.selected == item.value ? 'selected' : null}>
            {item.option}
          </option>
        );
      });
    }

    return (
      <div className="select-wrap">
        <label>{this.props.label}</label>
        <div className="select-container">
          <select
            className="select" 
            onChange={this.handleChange} 
          >
            {optionList}
          </select>
          <SelectDownIcon className="select-icon" />
        </div>
      </div>
    );
  }
}
export default UiSelect;
