import React, { useState, useEffect } from "react";

import "./UiPaginator.scss";

function UiPaginator(props) {
  const [pagesArr, setPagesArr] = useState([]);
  const [active, setActive] = useState(0);

  const pressPrev = () => { };
  const pressNext = () => { };
  const pressButton = () => { };

  useEffect(() => {
    let arr = [];
    for (let i = 1; i < Math.round(props.lastPage); i++) {
      arr.push(i);
    }
    setPagesArr(arr);

  }, [props.lastPage])

  let pages = pagesArr.map((item, index) => {
    if (index >= props.currentPage-1 && index < props.currentPage + 3) return (<button key={index} className={"paginator-num" + (props.currentPage == index + 1 ? " active" : "")} onClick={() => {
      props.onChange(item)
    }}>{item}</button>)

  })

  return (
    <div className="paginator">
      <div className="paginator-inner">
        <div className="paginator-button-wrap prev">
          <button 
            className={"paginator-button" + ( props.currentPage == 1 ? " disabled" : "")}
            onClick={() => {
              props.onChange(props.currentPage - 1)
            }}
          >Предыдущая</button>
        </div>
        {props.currentPage <= 3 ?
          <div className="paginator-num-buttons">
            <button className={"paginator-num" + (props.currentPage == 1 ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[0])
            }}>{pagesArr[0]}</button>
            <button className={"paginator-num" + (props.currentPage == 2 ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[1])
            }}>{pagesArr[1]}</button>
              <button className={"paginator-num" + (props.currentPage == 3 ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[2])
            }}>{pagesArr[2]}</button>
              <button className={"paginator-num" + (props.currentPage == 4 ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[3])
            }}>{pagesArr[3]}</button>
        
            <div className="paginator-spots">...</div>
            <button className="paginator-num" onClick={() => {
              props.onChange(pagesArr[pagesArr.length - 1])
            }}>{pagesArr[pagesArr.length - 1]}</button>
          </div>
          : null}

        {props.currentPage > 3 &&  props.currentPage < pagesArr.length - 4  ?
          <div className="paginator-num-buttons">
            <button className={"paginator-num" + (props.currentPage == 1 ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[0])
            }}>{pagesArr[0]}</button>
            <div className="paginator-spots">...</div>
            {pages}
            <div className="paginator-spots">...</div>
            <button className="paginator-num" onClick={() => {
              props.onChange(pagesArr[pagesArr.length - 1])
            }}>{pagesArr[pagesArr.length - 1]}</button>
          </div>
          : null}

        {props.currentPage > pagesArr.length - 3 ?
          <div className="paginator-num-buttons">
            <button className={"paginator-num" + (props.currentPage == 1 ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[0])
            }}>{pagesArr[0]}</button>
            <div className="paginator-spots">...</div>
          
            <button className={"paginator-num" + (props.currentPage == pagesArr[pagesArr.length - 4] ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[pagesArr.length - 4])
            }}>{pagesArr[pagesArr.length - 4]}</button>
            <button className={"paginator-num" + (props.currentPage == pagesArr[pagesArr.length - 3]? " active" : "")} onClick={() => {
              props.onChange(pagesArr[pagesArr.length - 3])
            }}>{pagesArr[pagesArr.length - 3]}</button>
            <button className={"paginator-num" + (props.currentPage == pagesArr[pagesArr.length - 2] ? " active" : "")} onClick={() => {
              props.onChange(pagesArr[pagesArr.length - 2])
            }}>{pagesArr[pagesArr.length - 2]}</button>
            <button className={"paginator-num" + (props.currentPage == pagesArr[pagesArr.length - 1]? " active" : "")} onClick={() => {
              props.onChange(pagesArr[pagesArr.length - 1])
            }}>{pagesArr[pagesArr.length - 1]}</button>
          </div>
          : null}

        <div className="paginator-button-wrap next">
          <button 
            className={"paginator-button" + ( props.currentPage >= pagesArr[pagesArr.length - 1] ? " disabled" : "")}
            onClick={() => {
              props.onChange(props.currentPage + 1)
            }}
          >Следующая</button>
        </div>
      </div>
    </div>
  );
}

export default UiPaginator;
